
export const environment = {
   production: true,
   google: {
      GA_TRACKING_ID: 'G-50J7YSZJ00'
   },
   api: 'https://soundbuttons.azure-api.net',
   origin: 'https://sound-buttons.click',
   version: '8d1d269e466af982ae2ea42dcc34a86a2ed5a4e1',
   CLARITY_TRACKING_ID: 'jnas47yqmj'
};
